.terms {
    background-color: #f4f7f6;
    padding: 10px;
    font-family: 'Arial', sans-serif;
        text-align: justify;
}

.title-text {
    font-size: 32px;
    color: #000000;
}

.sub-text {
    font-size: 20px;
    color: #666;
}

.mini-text {
    font-size: 12px;
    color: #666;
}
.StatsBackground {
    background-color: #002C3B;
    padding-top: 30px;
}

.description-text {
    display: flex;
    direction: rtl;
    text-align: justify;
    margin-left: 5vw;
    margin-right: 5vw;
    padding: 20px;
    border-radius: 10px;
    color: #ffffff;
    background: rgba(255, 255, 255, 0.1);
    line-height: 25px;
}
/*.content-container {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    padding-left: 5vw;*/
/*    padding-right: 5vw;*/
/*    justify-content: space-between;*/
/*}*/

.stats-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    padding-top: 25px;
    padding-bottom: 25px;
}

.stat {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.highlighted-text {
    display: flex;
    flex-direction: row;
    color: #ffffff;
    font-size: 40px;
}
.downloads,
.partners,
.properties,
.rating {
    display: block;
    font-size: 30px;
    color: rgba(185, 185, 185, 0.73);
}

@media (max-width: 767px) {
    .stats-container {
        flex-direction: column;
        gap: 40px;
    }
}
.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  /*padding: 50px;*/
  /*height: 100vh;*/
  /*padding: 20px;*/
}


.video-iframe {
  border-radius: 10px;
  margin: 50px;
}

.divider {
  width: 90%;
  height: 1px;
  background-color: rgba(185, 185, 185, 0.2);
  margin-top: 20px;
  border-radius: 25px;
}
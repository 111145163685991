.App {
    display: flex;
    flex-direction: column;
}

.header-container {
    display: flex;
    /*margin: 0 auto;*/
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.App-logo {
    min-width: 150px;
    width: 15vw;
    cursor: pointer;
    margin-left: 5vw;
    margin-top: 12px;
    margin-bottom: 12px;
}

ul {
    display: flex;
    flex-direction: row;
    gap: 10px;
    list-style: none;
    padding: 0 10px 0 0;
    margin: 0;
}

li {
    margin-bottom: 0;
}

.footer-container {
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 10px;
}

.contact-us-container {
    display: flex;
    /*margin: 0 auto;*/
    gap: 20px;
    flex-direction: row;
    position: absolute;
    right: 0;
    padding-right: 20px;
    padding-left: 10px;
}

@media (max-width: 767px) {
    .header-container {
        justify-content: center;
    }
}
